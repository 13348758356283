import { useState } from 'react';
import MenuItem from './MenuItem';
import { useStyles } from './style';
import { StyledMenu } from './styledComponents';

const Menu = () => {
  const [isExpanded, setExpanded] = useState(false);

  //@ts-ignore
  const classes = useStyles(isExpanded);

  const handleClick = () => {
    setExpanded(!isExpanded);
  };

  return (
    //@ts-ignore
    <StyledMenu isExpanded={isExpanded}>
      <div className={classes.menuContainer}>
        <MenuItem href="#about" name="О компании" />
        <MenuItem href="#team" name="Команда" />
        <MenuItem href="#contacts" name="Контакты" />
        <MenuItem href="#feedback" name="Обратная связь" />
      </div>
      <div className={classes.menuButtonContainer}>
        <button className={classes.menuButton} onClick={handleClick}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </StyledMenu>
  );
};

export default Menu;
