import { useStyles } from './style';

interface Props {
  name: string;
  href: string;
}

const MenuItem = ({ name, href }: Props) => {
  const classes = useStyles();

  return (
    <a className={classes.menuItem} href={href}>
      {name}
    </a>
  );
};

export default MenuItem;
