import { useStyles } from './style';

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <a href="#">Facebook</a>
      <a href="#">VK</a>
      <a href="#">Instagram</a>
    </div>
  );
};

export default Footer;
