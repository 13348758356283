import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  menuButton: (isExpanded) => ({
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 0,
    border: '3px solid white',
    background: 'none',
    width: '70px',
    height: '70px',
    transition: 'all 0.3s ease',
    '&:hover': {
      cursor: 'pointer',
      color: '#FFE600',
      border: '3px solid #FFE600',
      transition: 'all 0.3s ease',
      '& span': {
        backgroundColor: '#FFE600',
        transition: 'all 0.3s ease',
        '&:nth-child(2)': {
          backgroundColor: isExpanded ? 'rgba(50, 50, 50, 0)' : '#FFE600'
        }
      }
    },
    '& > span': {
      position: 'absolute',
      width: '35px',
      height: '3px',
      backgroundColor: '#FFF',
      transition: 'all 0.3s ease',
      '&:first-child': {
        transform: isExpanded ? 'rotate(45deg) ' : 'translateY(-10px)'
      },
      '&:last-child': {
        transform: isExpanded ? 'rotate(-45deg)' : 'translateY(10px)'
      },
      '&:nth-child(2)': {
        backgroundColor: isExpanded ? 'rgba(50, 50, 50, 0)' : '#FFF'
      }
    }
  }),
  menuButtonContainer: {
    marginTop: '30px',
    marginLeft: '30px'
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '450px',
    height: '100vh',
    backgroundColor: 'rgba(50, 50, 50, 0.8)',
    alignItems: 'center',
    paddingTop: '60px',
    '@media (max-width: 1359px)': {
      width: '360px'
    },
    '@media (min-width: 1360px) and (max-width: 1799px)': {
      width: '400px'
    }
  }
});
