import { WorkInfo } from '../../../types';
import { useStyles } from './style';
import { imageSrc } from '../../../utils';

interface WorkExampleProps {
  workInfo: WorkInfo;
  width: number;
  last?: boolean;
  lastSmall?: boolean;
}

const WorkExample = ({
  workInfo: { filmName, image },
  ...props
}: WorkExampleProps) => {
  //@ts-ignore
  const classes = useStyles({ ...props, image: imageSrc(image) });

  return (
    <div className={classes.workExample}>
      <span className={classes.workName}>{filmName}</span>
    </div>
  );
};

export default WorkExample;
