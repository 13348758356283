export const LOCALHOST = 'http://localhost:';

export const customUrl = (url: string, port = 8080) => {
  let customUrl = url;
  if (process.env.NODE_ENV === 'development') {
    customUrl = LOCALHOST + `${port}/` + customUrl;
  }
  return customUrl;
};

export const customFetch = async (
  url: string,
  options?: RequestInit,
  port = 8080
) => {
  const response = await fetch(customUrl(url, port), options);

  return response.json();
};

export const imageSrc = (imageName: string) =>
  customUrl(`images/work_examples/${imageName}`);
