import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  workExamples: {
    marginTop: '45px',
    marginBottom: '132px'
  },
  exaplesLine: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  exaplesLineLast: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1359px)': {
      flexWrap: 'wrap'
    }
  }
});
