import { useStyles } from './style';

interface SectionHeaderProps {
  sectionName: string;
  sectionAnchor: string;
}

const SectionHeader = ({ sectionName, sectionAnchor }: SectionHeaderProps) => {
  const classes = useStyles();

  return (
    <div className={classes.sectionHeader} id={sectionAnchor}>
      <span className={classes.sectionName}>{sectionName}</span>
    </div>
  );
};

export default SectionHeader;
