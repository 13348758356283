import { useStyles } from './style';

interface Props {
  isVisible: boolean;
}

const ArrowUp = ({ isVisible }: Props) => {
  const classes = useStyles();

  const handleClick = () => {
    window.scrollTo(window.scrollX, 0);
  };

  return isVisible ? (
    <div className={classes.arrowUp}>
      <button className={classes.button} onClick={handleClick}>
        ᐱ
      </button>
    </div>
  ) : null;
};

export default ArrowUp;
