import { createUseStyles } from 'react-jss';
import { customUrl } from '../../utils';

export const useStyles = createUseStyles({
  app: {
    backgroundImage: `url(${customUrl('images/back_objects.svg')})`,
    backgroundSize: '1920px',
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center'
  },
  siteContainer: {
    '@media (max-width: 1359px)': {
      width: '900px',
      paddingInline: '62px'
    },
    '@media (min-width: 1360px) and (max-width: 1799px)': {
      width: '1200px',
      paddingInline: '80px'
    },
    width: '1600px',
    paddingInline: '100px'
  }
});
