import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  contacts: {
    margin: '73px 0 95px'
  },
  contactList: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 300,
    fontSize: '30px',
    lineHeight: '37px',
    color: 'white'
  },
  phone: {
    '@media (max-width: 1359px)': {
      width: '400px'
    }
  },
  map: {
    height: '520px',
    marginTop: '53px',
    backgroundColor: 'gray'
  }
});
