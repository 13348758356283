import { useEffect, useState } from 'react';
import { customFetch } from '../../utils';
import Member from './Member';
import { useStyles } from './style';

export interface MemberInfo {
  photo: string;
  name: string;
  post: string;
}

const Team = () => {
  const classes = useStyles();

  const [team, setTeam] = useState<MemberInfo[]>([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      const response = await customFetch('team.json');
      setTeam(response);
    };

    if (team.length === 0) {
      fetchDataAsync();
    }
  }, [team.length]);

  console.log(team);

  return (
    <div className={classes.team}>
      {team.map(({ photo, name, post }, index) => (
        <Member
          key={index}
          photo={photo}
          name={name}
          post={post}
          last={index === team.length - 1}
        />
      ))}
    </div>
  );
};

export default Team;
