import { useStyles } from './style';

const Contacts = () => {
  const classes = useStyles();

  return (
    <div className={classes.contacts}>
      <div className={classes.contactList}>
        <span>г. Москва, ул. Мосфильмовская, д. 1</span>
        <span className={classes.phone}>+ 7 499 350 60 32</span>
        <span>corp@rideamus.org</span>
      </div>
      <div className={classes.map}>
        <iframe
          title="map"
          src="https://yandex.ru/map-widget/v1/?um=constructor%3A088f5c61948d9a023561e97520a3a3daf316c0116964594ecb6db88259ba8a8f&amp;source=constructor"
          width="100%"
          height="520"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default Contacts;
