import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    marginInline: '160px',
    paddingBottom: '114px',
    '& a': {
      color: 'white',
      textDecoration: 'none',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '30px',
      lineHeight: '37px',
      paddingInline: '130px'
    }
  }
});
