import { MemberInfo } from '..';
import { customUrl } from '../../../utils';
import { useStyles } from './style';

interface Props {
  last?: boolean;
}

const Member = ({ photo, name, post, last }: MemberInfo & Props) => {
  //@ts-ignore
  const classes = useStyles(last);

  return (
    <div className={classes.member}>
      <img src={customUrl(photo)} alt={name} />
      <div className={classes.name}>{name}</div>
      <div className={classes.post}>{post}</div>
    </div>
  );
};

export default Member;
