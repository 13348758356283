import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  team: {
    display: 'flex',
    justifyContent: 'center',
    margin: '58px 0 164px',
    flexWrap: 'wrap'
  }
});
