import styled from 'styled-components';

//@ts-ignore
export const StyledMenu = styled.div`
  position: fixed;
  transition: left 0.5s ease;
  display: flex;
  ${/*@ts-ignore*/ ''}
  left: ${(props) => (props.isExpanded ? 0 : '-450px')};
  @media (max-width: 1359px) {
    ${/*@ts-ignore*/ ''}
    left: ${(props) => (props.isExpanded ? 0 : '-360px')}
  }
  @media (min-width: 1360px) and (max-width: 1799px) {
    ${/*@ts-ignore*/ ''}
    left: ${(props) => (props.isExpanded ? 0 : '-400px')}
  }
`;
