import { useStyles } from './style';
import Partners from './Partners';

const About = () => {
  const classes = useStyles();

  return (
    <div className={classes.about}>
      <div className={classes.info}>
        <div>
          <span className={classes.upper}>
            <b>Rideamus</b> (латынь ) - будем смеяться.
          </span>
          <span>
            <br />
            Компания производит контент с 2016 года и производит только
            интересные проекты, <br />
            За это время было снято:
            <br />
          </span>
        </div>
        <ul>
          <li>7 сериалов</li>
          <li>4 пилота</li>
          <li>2 полных метра</li>
          <li>1 веб-сериал</li>
          <li>2 телепередачи</li>
          <li>20 и более рекламных роликов</li>
        </ul>
      </div>
      <Partners />
    </div>
  );
};

export default About;
