import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  bigLogoContainer: {
    marginTop: '288px',
    marginBottom: '328px',
    display: 'flex',
    justifyContent: 'center'
  },
  bigLogo: {
    width: '83%'
  }
});
