import { useStyles } from './style';
import WorkExample from './WorkExample';
import { useEffect, useState } from 'react';
import { customFetch } from '../../utils';
import { WorkInfo } from '../../types';

const WorkExamples = () => {
  const classes = useStyles();

  const [examplesInfo, setInfo] = useState<WorkInfo[]>([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      const response = await customFetch('examples.json');
      setInfo(response);
    };

    if (examplesInfo.length === 0) {
      fetchDataAsync();
    }
  }, [examplesInfo.length]);

  return examplesInfo.length === 0 ? null : (
    <div className={classes.workExamples}>
      <div className={classes.exaplesLine}>
        <WorkExample workInfo={examplesInfo[0]} width={798} />
        <WorkExample workInfo={examplesInfo[1]} width={798} last />
      </div>
      <div className={classes.exaplesLine}>
        <WorkExample workInfo={examplesInfo[2]} width={354} />
        <WorkExample workInfo={examplesInfo[3]} width={562} />
        <WorkExample workInfo={examplesInfo[4]} width={676} last />
      </div>
      <div className={classes.exaplesLine}>
        <WorkExample workInfo={examplesInfo[5]} width={614} />
        <WorkExample workInfo={examplesInfo[6]} width={982} last />
      </div>
      <div className={classes.exaplesLineLast}>
        <WorkExample workInfo={examplesInfo[7]} width={448} />
        <WorkExample workInfo={examplesInfo[8]} width={448} lastSmall />
        <WorkExample workInfo={examplesInfo[9]} width={402} />
        <WorkExample workInfo={examplesInfo[10]} width={494} last />
      </div>
    </div>
  );
};

export default WorkExamples;
