import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  partners: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '68px'
  },
  name: {
    fontWeight: 600,
    fontSize: '30px',
    textTransform: 'uppercase',
    color: '#FFFFFF'
  },
  partnersList: {
    display: 'flex',
    justifyContent: 'center'
  },
  logos: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '45px',
    '& img': {
      marginRight: '60px'
    }
  }
});
