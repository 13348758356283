import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  sectionHeader: {
    borderBottomStyle: 'solid',
    borderBottomColor: '#FFE600',
    borderBottomWidth: '4px',
    height: '44px',
    display: 'flex',
    justifyContent: 'center'
  },
  sectionName: {
    fontWeight: 600,
    fontSize: '50px',
    textTransform: 'uppercase',
    color: '#FFFFFF'
  }
});
