import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  about: {
    display: 'flex',
    flexDirection: 'column',
    paddingInline: '30px',
    margin: '34px 0 128px'
  },
  info: {
    fontSize: '24px',
    color: 'white'
  },
  upper: {
    display: 'inline-block',
    textTransform: 'uppercase',
    marginBottom: '20px'
  }
});
