import { useStyles } from './style';
import { customUrl } from '../../../utils';

const Partners = () => {
  const classes = useStyles();

  return (
    <div className={classes.partners}>
      <span className={classes.name}>Наши партнеры</span>
      <div className={classes.logos}>
        <div>
          <img
            src={customUrl('images/tonybraz_logo2.png')}
            alt="Tonybraz"
            style={{ width: '312px', height: '134px' }}
          />
        </div>
        <div>
          <img src={customUrl('images/logo_tnt.png')} alt="ТНТ" />
        </div>
        <div>
          <img src={customUrl('images/logo_tv3.png')} alt="ТВ3" />
        </div>
        <div>
          <img src={customUrl('images/logo_sts.png')} alt="СТС" />
        </div>
        <div>
          <img src={customUrl('images/logo_mosfilm.png')} alt="Мосфильм" />
        </div>
        <div>
          <img
            src={customUrl('images/cinema_logo.png')}
            alt="cinema"
            style={{ width: '138px', height: '78px' }}
          />
        </div>
        <div>
          <img
            style={{ marginRight: 0, width: '151px', height: '151px' }}
            src={customUrl('images/ant_logo.png')}
            alt="Ant"
          />
        </div>
      </div>
    </div>
  );
};

export default Partners;
