import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  menuItem: {
    display: 'flex',
    margin: '20px 0',
    color: '#FFF',
    textDecoration: 'none',
    fontStyle: 'normal',
    fontWeight: 600,
    textTransform: 'uppercase',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: '#FFE600',
      transition: 'color 0.3s ease'
    }
  }
});
