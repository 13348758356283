import { useStyles } from './style';
import { customUrl } from '../../utils';

const BigLogo = () => {
  const classes = useStyles();

  return (
    <div className={classes.bigLogoContainer}>
      <img
        className={classes.bigLogo}
        src={customUrl('images/bigLogo.svg')}
        alt="Big logo"
      />
    </div>
  );
};

export default BigLogo;
