import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  arrowUp: {
    position: 'fixed',
    bottom: '40px',
    left: '20px'
  },
  button: {
    padding: 0,
    border: '3px solid white',
    background: 'none',
    font: 'inherit',
    color: 'white',
    fontWeight: 300,
    fontSize: '30px',
    lineHeight: '37px',
    textTransform: 'uppercase',
    width: '55px',
    height: '60px',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      color: '#FFE600',
      border: '3px solid #FFE600',
      transition: 'all 0.3s ease'
    }
  }
});
