import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  member: (last) => ({
    width: '250px',
    fontSize: '30px',
    lineHeight: '33px',
    color: 'white',
    wordSpacing: '100vw',
    marginRight: last ? 0 : '20px',
    marginTop: '50px',
    '&>img': {
      width: '250px',
      borderRadius: '50%'
    },
    '@media (max-width: 1359px)': {
      width: '250px',
      marginRight: last ? 0 : '15px',
      '&>img': {
        width: '250px'
      }
    }
  }),
  name: {
    textAlign: 'center',
    marginTop: '43px',
    fontWeight: 300
  },
  post: {
    textAlign: 'center',
    marginTop: '8px',
    fontWeight: 600
  }
});
