import { useCallback, useEffect, useState } from 'react';
import About from '../About';
import ArrowUp from '../ArrowUp';
import Contacts from '../Contacts';
import Feedback from '../Feedback';
//import Header from '../Header';
import Menu from '../Menu';
import SectionHeader from '../SectionHeader';
import Team from '../Team';
import WorkExamples from '../WorkExamples';
import BigLogo from '../BigLogo';
import Footer from '../Footer';

import { useStyles } from './style';

const App = () => {
  const classes = useStyles();

  const [isArrowVisible, setArrowVisibility] = useState(false);

  const scrollHandler = useCallback(() => {
    if (window.scrollY < document.documentElement.clientHeight) {
      setArrowVisibility(false);
    } else if (isArrowVisible === false) {
      setArrowVisibility(true);
    }
  }, [isArrowVisible]);

  useEffect(() => {
    window.onscroll = scrollHandler;
  }, [scrollHandler]);

  return (
    <div className={classes.app}>
      <div className={classes.siteContainer}>
        {/* <Header /> */}
        <Menu />
        <BigLogo />
        <SectionHeader sectionName="Наши работы" sectionAnchor="works" />
        <WorkExamples />
        <SectionHeader sectionName="О компании" sectionAnchor="about" />
        <About />
        <SectionHeader sectionName="Команда" sectionAnchor="team" />
        <Team />
        <SectionHeader sectionName="Контакты" sectionAnchor="contacts" />
        <Contacts />
        {/* <SectionHeader sectionName="Обратная связь" sectionAnchor="feedback" />
        <Feedback /> */}
        <Footer />
      </div>
      <ArrowUp isVisible={isArrowVisible} />
    </div>
  );
};

export default App;
