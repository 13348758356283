import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  workExample: (props) => ({
    //@ts-ignore
    backgroundImage: `url(${props.image})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '350px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '4px',
    alignItems: 'end',
    //@ts-ignore
    marginRight: `${props.last ? 0 : 4}px`,
    //@ts-ignore
    width: `${props.width}px`,
    '@media (min-width: 1360px) and (max-width: 1799px)': {
      height: '310px'
    },
    '@media (max-width: 1359px)': {
      height: '280px',
      //@ts-ignore
      marginRight: `${props.lastSmall || props.last ? 0 : 4}px`
    }
  }),
  workName: {
    fontWeight: 300,
    textAlign: 'center',
    fontSize: '40px',
    lineHeight: '43px',
    color: 'white',
    marginBottom: '24px',
    letterSpacing: '0.23em',
    '@media (max-width: 1799px)': {
      letterSpacing: '0.2em'
    }
  }
});
